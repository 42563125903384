import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 60vh;

  h2 {
    margin-top: 32px;
    text-align: center;
    font-weight: normal;
    font-size: 28px;
  }

  > img {
    max-width: 70px;
    opacity: 1;
    margin-top: 32px;
  }

  @media (min-width: 600px) {
    > img {
      max-width: 150px;
    }
  }
`;
