import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
  border-radius: 12px;
  padding: 16px;

  @media (min-width: 600px) {
    padding: 20px;
  }

  @media (min-width: 900px) {
    padding: 24px;
  }
`;

export const Top = styled.div`
  display: flex;
  align-items: flex-start;

  h1 {
    font-size: 14px;
    color: #023955;
    font-weight: bold;
    padding: 0 8px;
    line-height: 26px;
  }

  h2 {
    line-height: 22px;
    font-size: 14px;
    color: #023955;
    font-weight: normal;
    margin-left: 8px;
  }

  @media (min-width: 600px) {
    h1 {
      font-size: 16px;
    }
    h2 {
      font-size: 16px;
    }
  }

  @media (min-width: 900px) {
    h1 {
      font-size: 18px;
    }
    h2 {
      font-size: 18px;
    }
  }
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;

export const BottomLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 8px;
`;

export const BottomFuel = styled.div`
  position: relative;

  img {
    width: 44px;
    height: auto;
  }

  p {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
    font-weight: bold;
  }
`;

export const EmptyButton = styled.button`
  color: #c74d4e;
  border: 0;
  background-color: transparent;
  height: 32px;
  font-size: 14px;

  transition: color 0.2s;

  &:hover {
    color: #b34546;
  }
`;

export const BottomRight = styled.div`
  height: 56px;
  display: flex;
  align-items: center;

  button + button {
    margin-left: 8px;
  }
`;

export const PlusButton = styled.button`
  width: 44px;
  height: 44px;
  font-size: 16px;
  border-radius: 50%;
  border-width: 3px;
  color: #142331;
  border-color: #142331;
  background-color: transparent;
  font-weight: bold;
  transition: color 0.2s, background-color 0.2s;

  &:hover {
    color: #fff;
    border-color: #142331;
    background-color: #142331;
  }
`;
