import styled from 'styled-components';

export const Container = styled.div`
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 580px;
  margin: 0 auto;

  form {
    width: 100%;
  }

  h2 {
    font-size: 16px;
    font-weight: normal;
    margin: 16px 0;
  }
`;

export const Content = styled.div`
  margin: 16px 0;
  text-align: justify;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const ContentImage = styled.div`
  display: flex;
  justify-content: center;
  margin: 16px 0;
  max-height: 100px;

  > img {
    border-radius: 50%;
    width: 100px;
    height: auto;
    opacity: 0.8;
  }
`;

export const ContainerCheckbox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin-top: -15px;
  margin-bottom: -15px;

  input {
    margin-right: 10px;
    margin-left: 3px;
    transform: scale(1.5);
  }
`;
