import styled from 'styled-components';

export const Container = styled.div``;

export const Top = styled.header`
  span {
    background-color: var(--white);
    border-radius: 50%;
    display: inline-block;
    width: 32px;
    height: 32px;
    text-align: center;
    color: var(--primary-dark);
    font-size: 1rem;
    line-height: 32px;
    font-weight: bold;
  }

  p {
    font-size: 1rem;
    font-weight: normal;
    margin: 1.5rem 0;
  }
`;
