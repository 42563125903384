import styled, { css, keyframes } from 'styled-components';

interface IRemainingContainerProps {
  hasLimitError: boolean;
}

export const Container = styled.div`
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;

  > form {
    width: 100%;
  }

  > h2 {
    font-size: 1rem;
    font-weight: normal;
    margin: 1.5rem 0;
  }

  > h3 {
    font-size: 0.8rem;
    font-weight: lighter;
    text-align: center;
    max-width: 400px;
    margin: 0 auto;
    opacity: 0.8;
  }
`;

export const Content = styled.div`
  margin: 1.6rem 0;

  > div + div {
    margin-top: 16px;
  }

  > h1 {
    width: 100%;
    text-align: left;
    font-size: 24px;
    font-weight: normal;
    margin-top: 16px;
  }

  > h2 {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
    font-weight: normal;
    margin: 36px 0;
  }

  > p {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    margin-top: 32px;
    margin-bottom: 16px;
    color: rgba(255, 255, 255, 0.8);
  }

  @media (min-width: 600px) {
    > h2 {
      font-size: 16px;
    }
  }

  @media (min-width: 900px) {
    > h2 {
      font-size: 18px;
    }
  }
`;

const shake = keyframes`
  \ 0% {
    transform: translateX(0);
  }
  \ 33% {
    transform: translateX(-10px);
  }
  \ 66% {
    transform: translateX(10px);
  }
  \ 100%   {
    transform: translateX(0)
  }
`;

export const RemainingContainer = styled.div<IRemainingContainerProps>`
  position: fixed;
  top: 0;
  right: 16px;
  background-color: #009df8;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;

  border-bottom: 3px solid transparent;
  border-right: 3px solid transparent;
  border-left: 3px solid transparent;

  > span.error {
    color: #ffcb00;
    font-size: 14px;
    text-align: center;
  }

  ${props =>
    props.hasLimitError &&
    css`
      border-bottom: 3px solid #ffcb00;
      border-right: 3px solid #ffcb00;
      border-left: 3px solid #ffcb00;

      animation: ${shake} 0.4s both normal;
    `}

  @media (min-width: 900px) {
    right: calc(50% - 450px);
  }
`;

export const RemainingContent = styled.div`
  display: flex;
  align-items: center;

  h1 {
    font-size: 20px;
    font-weight: 400;
    margin-right: 8px;
  }

  @media (min-width: 600px) {
    flex-direction: column;
    align-items: center;

    h1 {
      display: block;
      font-size: 24px;
    }
  }
`;

export const RemainingFuel = styled.div`
  display: flex;
  align-items: center;

  p {
    font-size: 36px;
    font-weight: 600;
  }

  img {
    height: 36px;
    width: auto;
    margin-left: 12px;
  }
`;

export const WarningContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    color: #ffcb00;
    text-align: center;
  }

  svg {
    color: #ffcb00;
    height: 16px;
    width: 16px;
    margin-bottom: 8px;
  }

  @media (min-width: 600px) {
    align-items: center;
    justify-content: center;
    flex-direction: row;

    svg {
      margin-bottom: 0;
      margin-right: 8px;
    }
  }
`;
