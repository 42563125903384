import { IOption } from '../../hooks/priority';

const options = [
  {
    id: '0',
    name: 'Javascript',
    priority: '0',
  },
  {
    id: '1',
    name: 'Go',
    priority: '0',
  },
  {
    id: '2',
    name: 'Swift',
    priority: '0',
  },
  {
    id: '3',
    name: 'Typescript',
    priority: '0',
  },
  {
    id: '4',
    name: 'Java',
    priority: '0',
  },
  {
    id: '5',
    name: 'Kotlin',
    priority: '0',
  },
  {
    id: '6',
    name: 'Lisp',
    priority: '0',
  },
  {
    id: '7',
    name: 'Visual Basic .NET',
    priority: '0',
  },
  {
    id: '8',
    name: 'Elixir',
    priority: '0',
  },
  {
    id: '9',
    name: 'Ruby',
    priority: '0',
  },
  {
    id: '10',
    name: 'Python',
    priority: '0',
  },
  {
    id: '11',
    name: 'Lua',
    priority: '0',
  },
  {
    id: '12',
    name: 'COBOL',
    priority: '0',
  },
  {
    id: '13',
    name: 'SQL',
    priority: '0',
  },
  {
    id: '14',
    name: 'C#',
    priority: '0',
  },
  {
    id: '15',
    name: 'Perl',
    priority: '0',
  },
  {
    id: '16',
    name: 'C',
    priority: '0',
  },
  {
    id: '17',
    name: 'C++',
    priority: '0',
  },
  {
    id: '18',
    name: 'Dart',
    priority: '0',
  },
  {
    id: '19',
    name: 'Elm',
    priority: '0',
  },
] as IOption[];

export default options;
