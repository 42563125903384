import styled, { css } from 'styled-components';

interface IListItemProps {
  selected: boolean;
  lowOpacity: boolean;
}

export const Top = styled.header`
  span {
    background-color: var(--white);
    border-radius: 50%;
    display: inline-block;
    width: 32px;
    height: 32px;
    text-align: center;
    color: var(--primary-dark);
    font-size: 1rem;
    line-height: 32px;
    font-weight: bold;
  }

  p {
    font-size: 1rem;
    font-weight: normal;
    margin: 1.5rem 0;
  }
`;

export const ListContainer = styled.ul`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 15px;
  margin-top: 30px;

  h2 {
    font-size: 1rem;
    font-weight: normal;
    margin: 1.5rem 0;
  }

  h3 {
    font-size: 0.8rem;
    font-weight: lighter;
    text-align: center;
    max-width: 400px;
    margin: 0 auto;
    opacity: 0.8;
  }

  @media (min-width: 720px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ListItem = styled.li<IListItemProps>`
  padding: 20px;
  border-radius: 4px;
  background: #fff;
  min-width: 60px;

  opacity: 0.9;
  color: var(--primary);
  cursor: pointer;
  border: 0;

  ${props =>
    props.selected &&
    css`
      opacity: 1;
      color: var(--primary-dark);
    `}

  ${props =>
    props.lowOpacity &&
    css`
      opacity: 0.6;
    `}

  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    margin-right: 1rem;
    background-color: var(--primary-dark);
    color: #fff;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 32px;
  }

  strong {
    font-size: 18px;
    flex: 1;
    margin-right: 8px;
    font-weight: normal;
    text-align: left;
  }

  img {
    width: 16px;
    height: 16px;
  }

  transition: transform 0.2s, box-shadow 0.2s;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.4);
  }
`;
