import React, { useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { FiMail, FiBriefcase } from 'react-icons/fi';

import getValidationErrors from '../../../utils/getValidationErrors';
import { useFuel } from '../../../hooks/fuel';

import Button from '../../../components/Button';
import Input from '../../../components/Input';
import Select from '../../../components/ReactSelect/Select';
import Header100GallonGame from '../../../components/Header100GallonGame';

import { months, years, regional, segment } from './data';
import astronaut from '../../../assets/astronaut.svg';

import { Container, Content, ContentImage, ContainerCheckbox } from './styles';
import { AnimationContainer } from '../styles';

interface IPersonalDataFormData {
  role: string;
  regional: string;
  segment: string;
  month: string;
  year: string;
  email?: string;
}

const PersonalData: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { survey, updateSurvey } = useFuel();
  const [acceptToBeIdentified, setAcceptToBeIdentified] = useState<boolean>(
    false,
  );

  const handleSubmit = useCallback(
    async (data: IPersonalDataFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          role: Yup.string().required('Por favor, insira o seu cargo'),
          regional: Yup.string().required('Por favor, insira sua regional'),
          segment: Yup.string().required(
            'Por favor, insira o seu segmento de negócio',
          ),
          month: Yup.string().required('Por favor, insira o mês da admissão'),
          year: Yup.string().required('Por favor, insira o ano da admissão'),
          email: Yup.string().when([], {
            is: () => acceptToBeIdentified,
            then: Yup.string()
              .email('Insira um e-mail válido')
              .required('Necessário informar um e-mail'),
            otherwise: Yup.string().nullable(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (survey) {
          const newPersonalData: IPersonalDataFormData = {
            regional: data.regional,
            role: data.role,
            segment: data.segment,
            month: data.month,
            year: data.year,
          };

          if (data.email) Object.assign(newPersonalData, { email: data.email });

          window.scrollTo(0, 0);

          updateSurvey({
            ...survey,
            personalData: newPersonalData,
            step: 'tutorial',
          });
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
      }
    },
    [survey, updateSurvey, acceptToBeIdentified],
  );

  const selectStyle = {
    control: (base: any, state: any) => ({
      ...base,
      background: 'rgba(255, 255, 255, 0.4)',
      height: 50,
      width: '100%',
      paddingLeft: 16,
      marginTop: 10,
      marginBottom: 10,
      borderRadius: 2,
      borderColor: state.isFocused ? '#FFFFFF' : '#282828',
      boxShadow: state.isFocused ? null : null,
      '&:hover': {
        borderColor: state.isFocused ? '#FFFFFF' : '#282828',
      },
    }),
    menu: (base: any) => ({
      ...base,
      borderRadius: 0,
      hyphens: 'auto',
      marginTop: 0,
      textAlign: 'left',
      wordWrap: 'break-word',
    }),
    menuList: (base: any) => ({
      ...base,
      padding: 0,
      height: 200,
    }),
    option: (provided: any, state: { isSelected: any }) => ({
      ...provided,
      color: state.isSelected ? '#fff' : '#fff',
      backgroundColor: state.isSelected ? '#363636' : '#363636',
      paddingTop: 16,
      paddingBottom: 16,
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: '#fff',
    }),
    placeholder: (base: any) => ({
      ...base,
      color: '#ffffff',
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      color: '#fff',
    }),
  };

  return (
    <AnimationContainer>
      <Container>
        <Header100GallonGame />

        <Content>
          <h2>Bem-vindo à nova era de inovação do Grupo GPS.</h2>

          <h2>
            Construímos uma base de lançamento para foguetes e precisamos da sua
            ajuda para os lançamentos. Esses foguetes são soluções para os
            problemas do Grupo GPS e a quantidade de combustível e quais
            foguetes serão abastecidos cabe a você decidir.
          </h2>

          <ContentImage>
            <img src={astronaut} alt="Astronauta" />
          </ContentImage>

          <h2>
            Está preparado para nos ajudar nesses lançamentos? Fique tranquilo,
            seus dados serão preservados. A pesquisa será anônima.
          </h2>
        </Content>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Input
            name="role"
            icon={FiBriefcase}
            type="text"
            placeholder="Cargo"
          />
          <Select
            name="regional"
            options={regional}
            styles={selectStyle}
            placeholder="Regional"
          />
          <Select
            name="segment"
            options={segment}
            styles={selectStyle}
            placeholder="Segmento de Negócio"
          />
          <Select
            name="month"
            options={months}
            styles={selectStyle}
            placeholder="Mês admissão"
          />
          <Select
            name="year"
            options={years}
            styles={selectStyle}
            placeholder="Ano admissão"
          />
          <ContainerCheckbox>
            <input
              name="acceptToBeIdentified"
              type="checkbox"
              checked={acceptToBeIdentified}
              onChange={e => setAcceptToBeIdentified(e.target.checked)}
            />
            <h2>Quero me identificar (opcional)</h2>
          </ContainerCheckbox>
          {acceptToBeIdentified && (
            <Input
              name="email"
              icon={FiMail}
              type="text"
              placeholder="E-mail da GPS"
            />
          )}

          <Button type="submit">Próximo</Button>
        </Form>
      </Container>
    </AnimationContainer>
  );
};

export default PersonalData;
