import React, { useCallback, useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { FiNavigation } from 'react-icons/fi';

import getValidationErrors from '../../utils/getValidationErrors';

import Button from '../../components/Button';
import Input from '../../components/Input';

import logo from '../../assets/rocket-spark.svg';

import { Wrapper, AnimationContainer, Container, Top, Content } from './styles';

interface IHomeFormData {
  slug: string;
}

const Home: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: IHomeFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          slug: Yup.string().required('Por favor, insira o código da pesquisa'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (data.slug === 'foguetesgps') {
          history.push(`/jogodosgaloes/${data.slug}`);
        } else {
          history.push(`/prioridade/${data.slug}`);
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
      }
    },
    [history],
  );

  return (
    <Wrapper>
      <AnimationContainer>
        <Container>
          <Top>
            <img src={logo} alt="Logo GPS Inova" />
            <h1>GPS Inova Survey</h1>
          </Top>

          <Content>
            <h2>Bem-vindo ao GPS Inova Survey!</h2>

            <h2>
              Para começar digite o código fornecido e entre na pesquisa que
              quer participar.
            </h2>
          </Content>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <Input
              name="slug"
              icon={FiNavigation}
              type="text"
              placeholder="Digite o código da pesquisa"
            />

            <Button type="submit">Entrar na pesquisa</Button>
          </Form>
        </Container>
      </AnimationContainer>
    </Wrapper>
  );
};

export default Home;
