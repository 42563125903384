import styled, { css } from 'styled-components';

interface IContainer100GallonsProps {
  left: boolean;
}

export const Container = styled.div<IContainer100GallonsProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8px;

  h1 {
    font-size: 36px;
    font-weight: bold;
    text-align: center;
  }

  img {
    height: 36px;
    width: auto;
    margin-bottom: 8px;
  }

  @media (min-width: 600px) {
    flex-direction: row;
    align-items: center;
    justify-content: center;

    ${props =>
      props.left &&
      css`
        justify-content: flex-start;
      `}

    h1 {
      text-align: left;
    }

    img {
      margin-bottom: 0;
      margin-right: 16px;
    }
  }
`;
