import React, { ButtonHTMLAttributes } from 'react';

import LoadingAnimation from '../Loading';

import { Container } from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  disabled?: boolean;
};

const Button: React.FC<ButtonProps> = ({
  children,
  loading,
  disabled = false,
  ...rest
}) => (
  <Container type="button" disabled={disabled} {...rest}>
    {loading ? <LoadingAnimation /> : children}
  </Container>
);

export default Button;
