import React from 'react';
import { Container } from './styles';
import gallon from '../../assets/gallon.svg';

interface IHeader100GallonGameProps {
  left?: boolean;
}

const Header100GallonGame: React.FC<IHeader100GallonGameProps> = ({
  left = false,
}) => (
  <Container left={left}>
    <img src={gallon} alt="Jogo dos 100 Galões" />
    <h1>Jogo dos 100 Galões</h1>
  </Container>
);

export default Header100GallonGame;
