import React, { useMemo } from 'react';

import { FuelProvider } from '../../hooks/fuel';

import FuelSurveySteps from './Steps';
import ExpiredSurvey from '../../components/ExpiredSurvey';

const FuelSurvey: React.FC = () => {
  const isExpired = useMemo(() => {
    const expireDate = new Date(2021, 5, 30, 23, 59, 59);
    const newDate = new Date();

    return newDate > expireDate;
  }, []);

  return (
    <FuelProvider>
      {!isExpired ? <FuelSurveySteps /> : <ExpiredSurvey />}
    </FuelProvider>
  );
};

export default FuelSurvey;
