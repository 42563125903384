import React from 'react';

import { Wrapper, BackgroundImage, Container, Bottom } from './styles';

import logo from '../../../assets/rocket-spark.svg';

const PublicLayout: React.FC = ({ children }) => (
  <Wrapper>
    <BackgroundImage>
      <Container>{children}</Container>
      <Bottom>
        <img src={logo} alt="Logo GPS Inova" />
        <span>@gpsinova</span>
      </Bottom>
    </BackgroundImage>
  </Wrapper>
);

export default PublicLayout;
