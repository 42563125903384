import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useFuel } from '../../../hooks/fuel';

import PersonalData from '../PersonalData';
import Tutorial from '../Tutorial';
import FuelDistribution from '../FuelDistribution';
import Finish from '../Finish';

import { Container } from './styles';

interface ISurveyParams {
  slug: string;
}

const FuelSurveySteps: React.FC = () => {
  const { survey, setSlug } = useFuel();
  const params = useParams<ISurveyParams>();

  useEffect(() => {
    setSlug(params.slug);
  }, [params.slug, setSlug]);

  return (
    <Container>
      {survey?.step === 'personalData' && <PersonalData />}
      {survey?.step === 'tutorial' && <Tutorial />}
      {survey?.step === 'fuelDistribution' && <FuelDistribution />}
      {survey?.step === 'finish' && <Finish />}
    </Container>
  );
};

export default FuelSurveySteps;
