import React from 'react';

import { Switch } from 'react-router-dom';

import Route from './Route';

import Home from '../pages/Home';
import PrioritySurvey from '../pages/PrioritySurvey';
import FuelSurvey from '../pages/FuelSurvey';

const Routes: React.FC = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/prioridade/:slug" component={PrioritySurvey} />
    <Route exact path="/jogodosgaloes/:slug" component={FuelSurvey} />
  </Switch>
);

export default Routes;
