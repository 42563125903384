import React from 'react';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '../Button';

import { Container } from './styles';
import { AnimationContainer } from '../../pages/PrioritySurvey/styles';

const ExpiredSurvey: React.FC = () => {
  const history = useHistory();

  const goBack = useCallback(() => {
    history.push('/');
  }, [history]);

  return (
    <AnimationContainer>
      <Container>
        <h2>Esta pesquisa expirou!</h2>

        <Button type="button" onClick={goBack}>
          Voltar
        </Button>
      </Container>
    </AnimationContainer>
  );
};

export default ExpiredSurvey;
