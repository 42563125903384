import styled from 'styled-components';

export const Container = styled.div`
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 580px;
  margin: 0 auto;

  form {
    width: 100%;
  }

  > h1 {
    width: 100%;
    text-align: center;
    font-size: 24px;
    font-weight: normal;
    margin-top: 16px;
  }

  h2 {
    font-size: 1rem;
    font-weight: normal;
    margin: 16px 0;
  }

  > img {
    width: 100%;
    height: auto;
  }
`;
