import styled, { keyframes } from 'styled-components';

export const Wrapper = styled.div`
  max-width: 600px;
  margin: 50px auto;

  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 580px;

  form {
    width: 100%;
  }

  h2 {
    font-size: 1rem;
    font-weight: normal;
    margin: 1.5rem 0;
  }

  h3 {
    font-size: 0.8rem;
    font-weight: lighter;
    text-align: center;
    max-width: 400px;
    margin: 0 auto;
    opacity: 0.8;
  }
`;

export const Top = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 1.4rem;
    margin: 0 auto;
  }

  img {
    height: 76px;
    width: 76px;
    margin-right: 8px;
    margin: 16px auto;
  }
`;

export const Content = styled.div`
  margin: 1.6rem 0;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  animation: ${appearFromLeft} 1s;
`;
