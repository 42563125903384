import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { IPriorityNumbers, usePriority } from '../../../hooks/priority';

import Button from '../../../components/Button';
import { AnimationContainer } from '../styles';

import { Bottom } from '../styles';
import { Container, Top } from './styles';
import api from '../../../services/api';

interface IFinishProps {
  stepNumber: number;
}

interface ISendOption {
  id: string;
  priority: string;
}

interface ISendSurveyFormData {
  email: string;
  slug: string;
  options: ISendOption[];
}

const Finish: React.FC<IFinishProps> = ({ stepNumber }) => {
  const { survey, updateSurvey } = usePriority();
  const history = useHistory();

  const handleSubmit = useCallback(async () => {
    try {
      if (survey && survey.options) {
        const newSurveyOptions = survey.options.map(item => ({
          id: item.id,
          priority: item.priority,
        })) as ISendOption[];

        const newSurvey = {
          email: survey.email,
          slug: survey.slug,
          options: newSurveyOptions,
        } as ISendSurveyFormData;

        await api.post('/survey/answer', newSurvey);

        alert('Resposta salva!');

        const cleanOptions = survey.options.map(item => ({
          id: item.id,
          name: item.name,
          priority: '0' as IPriorityNumbers,
        }));

        updateSurvey({
          name: survey.name,
          description: survey.description,
          email: null,
          options: cleanOptions,
          slug: survey.slug,
          step: 'email',
        });

        history.push('/');
      }
    } catch (error) {
      alert(
        'Não foi possível enviar as suas resposta, tente novamente mais tarde.',
      );
    }
  }, [history, survey, updateSurvey]);

  return (
    <AnimationContainer>
      <Container>
        <Top>
          <span>{stepNumber}</span>
          <p>Clique em enviar para finalizar seu questionário.</p>
        </Top>

        <Bottom>
          <Button type="button" onClick={() => handleSubmit()}>
            Enviar
          </Button>
        </Bottom>
      </Container>
    </AnimationContainer>
  );
};

export default Finish;
