import React from 'react';

import Header100GallonGame from '../../../components/Header100GallonGame';
import { Container } from './styles';
import { AnimationContainer } from '../styles';

import astronautFinish from '../../../assets/astronautFinish.svg';

const Finish: React.FC = () => (
  <AnimationContainer>
    <Container>
      <Header100GallonGame />

      <img src={astronautFinish} alt="Astronatua final" />

      <h2>Obrigado por participar!</h2>
    </Container>
  </AnimationContainer>
);

export default Finish;
