import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 60vh;
  max-width: 600px;
  margin: 0 auto;

  h2 {
    margin-top: 32px;
    text-align: center;
    font-weight: normal;
    font-size: 28px;
    margin-bottom: 16px;
  }
`;
