import React, {
  useState,
  createContext,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';

import initialData from '../pages/FuelSurvey/data';
import shuffleArray from '../utils/shuffleArray';

type IStepOptions = 'personalData' | 'tutorial' | 'fuelDistribution' | 'finish';

interface IPersonalData {
  role: string;
  regional: string;
  segment: string;
  month: string;
  year: string;
  email?: string;
}

export interface IFuelDistributionData {
  id: string;
  formsId: string;
  title: string;
  amount: number;
}

interface ISurvey {
  slug: string;
  personalData: IPersonalData | null;
  fuelDistribution: IFuelDistributionData[];
  remainingFuel: number;
  step: IStepOptions;
}

interface IFuelContextData {
  survey: ISurvey | null;
  updateSurvey(data: ISurvey): void;
  setSlug(data: string): void;
}

const FuelContext = createContext<IFuelContextData>({} as IFuelContextData);

const FuelProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const [slug, setSlug] = useState<string | null>(null);
  const [survey, setSurvey] = useState<ISurvey | null>(null);

  useEffect(() => {
    if (slug) {
      const shuffledInitialData = shuffleArray(initialData);

      setSurvey({
        personalData: null,
        fuelDistribution: shuffledInitialData,
        remainingFuel: 100,
        slug,
        step: 'personalData',
      });
    }
  }, [history, slug]);

  const updateSurvey = useCallback(async (data: ISurvey) => {
    setSurvey(data);
  }, []);

  return (
    <FuelContext.Provider
      value={{
        survey,
        updateSurvey,
        setSlug,
      }}
    >
      {children}
    </FuelContext.Provider>
  );
};

function useFuel(): IFuelContextData {
  const context = useContext(FuelContext);

  if (!context) {
    throw new Error('useFuel must be used within FuelProvider');
  }

  return context;
}

export { FuelProvider, useFuel };
