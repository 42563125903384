import React from 'react';

import { PriorityProvider } from '../../hooks/priority';

import PrioritySurveySteps from './Steps';

const PrioritySurvey: React.FC = () => (
  <PriorityProvider>
    <PrioritySurveySteps />
  </PriorityProvider>
);

export default PrioritySurvey;
