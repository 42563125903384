import styled from 'styled-components';

export const Container = styled.div`
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 580px;
  margin: 0 auto;

  form {
    width: 100%;
  }

  h2 {
    font-size: 1rem;
    font-weight: normal;
    margin: 1.5rem 0;
  }

  h3 {
    font-size: 0.8rem;
    font-weight: lighter;
    text-align: center;
    max-width: 400px;
    margin: 0 auto;
    opacity: 0.8;
  }
`;

export const Top = styled.div`
  display: flex;
  align-items: center;

  h1 {
    font-size: 1.4rem;
  }

  img {
    height: 44px;
    width: 44px;
    margin-right: 8px;
  }
`;

export const Content = styled.div`
  margin: 1.6rem 0;
`;
