import styled, { css } from 'styled-components';

interface IContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
  isDisabled: boolean;
}

export const Wrapper = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;

  label {
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    color: var(--background);
  }

  span.error {
    font-style: normal;
    font-size: 1rem;
    color: var(--red);
    display: inline-block;
    margin-bottom: 0.5rem;
  }
`;

export const Container = styled.div<IContainerProps>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 32px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 2px;
  padding: 24px;
  border: 1px solid transparent;
  color: var(--text);
  margin: 0.5rem 0;

  & + div {
    margin-top: 1rem;
  }

  ${props =>
    props.isErrored &&
    css`
      border-color: var(--red);
    `}

  ${props =>
    props.isFocused &&
    css`
      color: var(--white);
      border-color: var(--white);
    `}


  ${props =>
    props.isFilled &&
    css`
      color: var(--white);
    `}

  input {
    flex: 1;
    width: 100%;

    background: transparent;
    border: 0;
    margin-right: 8px;
    color: var(--text);
    outline: none;

    &::placeholder {
      color: var(--text);
    }
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }

  svg {
    flex-shrink: 0;
    height: 20px;
    width: 20px;
  }

  /* ${props =>
    props.isDisabled &&
    css`
      background-color: #f1f1f1;

      input {
        background: #f1f1f1;
      }
    `} */
`;
