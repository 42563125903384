import styled, { css } from 'styled-components';

interface IContainerButton {
  disabled: boolean;
}

export const Container = styled.button<IContainerButton>`
  cursor: pointer;
  height: 48px;
  border-radius: 2px;
  border: 0;
  padding: 0 1rem;
  font-weight: 500;
  margin: 1rem 0;
  width: 100%;
  outline: none;
  color: var(--primary-darker);

  background: rgba(255, 255, 255, 0.9);

  transition: background 0.2s, transform 0.2s, box-shadow 0.2s;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
    background: rgba(255, 255, 255, 1);
  }

  ${props =>
    props.disabled &&
    css`
      background: rgba(0, 0, 0, 0.4);
      pointer-events: none;
    `}
`;
