import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { usePriority } from '../../../hooks/priority';

import Email from '../Email';
import Finish from '../Finish';
import List from '../List';

import { Container } from './styles';

interface ISurveyParams {
  slug: string;
}

const PrioritySurveySteps: React.FC = () => {
  const { survey, setSlug } = usePriority();
  const params = useParams<ISurveyParams>();

  useEffect(() => {
    setSlug(params.slug);
  }, [params.slug, setSlug]);

  return (
    <Container>
      {survey?.options && (
        <>
          {survey.step === 'email' && <Email />}
          {survey.step === 'initialBest' && (
            <List
              stepNumber={1}
              options={survey.options}
              priority="0"
              nextPriority="1"
              nextStep="best"
              maxItens={6}
            />
          )}
          {survey.step === 'best' && (
            <List
              stepNumber={2}
              options={survey.options.filter(item => item.priority === '1')}
              priority="1"
              nextPriority="2"
              nextStep="initialWrost"
              maxItens={3}
            />
          )}
          {survey.step === 'initialWrost' && (
            <List
              stepNumber={3}
              options={survey.options.filter(item => item.priority === '0')}
              priority="0"
              nextPriority="-1"
              nextStep="wrost"
              maxItens={6}
            />
          )}
          {survey.step === 'wrost' && (
            <List
              stepNumber={4}
              options={survey.options.filter(item => item.priority === '-1')}
              priority="-1"
              nextPriority="-2"
              nextStep="finish"
              maxItens={3}
            />
          )}
          {survey.step === 'finish' && <Finish stepNumber={5} />}
        </>
      )}
    </Container>
  );
};

export default PrioritySurveySteps;
