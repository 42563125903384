import React from 'react';
import {
  RouteProps as ReactDOMRouteProps,
  Route as ReactDOMRoute,
} from 'react-router-dom';

import PublicLayout from '../pages/_layouts/public';

interface IRouteProps extends ReactDOMRouteProps {
  component: React.ComponentType;
}

const Route: React.FC<IRouteProps> = ({ component: Component, ...rest }) => {
  const Layout = PublicLayout;

  return (
    <ReactDOMRoute
      {...rest}
      render={() => (
        <Layout>
          <Component />
        </Layout>
      )}
    />
  );
};

export default Route;
