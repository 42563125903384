import { IFuelDistributionData } from '../../hooks/fuel';

const options = [
  // Operação
  {
    formsId: 'entry.218454684',
    id: '1',
    title:
      'Melhorias no controle/gestão de estoques de insumos em minha operação é uma prioridade para mim.',
    amount: 0,
  },
  {
    formsId: 'entry.677919651',
    id: '2',
    title:
      'Acredito que a performance do meu contrato poderia ser melhorada se nos empenhássemos mais no processo de compras/negociação e descoberta de novos fornecedores.',
    amount: 0,
  },
  {
    formsId: 'entry.1865529548',
    id: '3',
    title:
      'Reduziríamos, consideravelmente, o tempo de atendimento ao Cliente em minha operação se os prazos de entregas de produtos/insumos fossem otimizados.',
    amount: 0,
  },
  {
    formsId: 'entry.2146647323',
    id: '4',
    title:
      'A gestão da quantidade e/ou controle de vencimento de uniformes/EPIs tem muita margem para melhoria.',
    amount: 0,
  },
  {
    formsId: 'entry.1696079169',
    id: '5',
    title:
      'Recursos tecnológicos de software (como GPSi, GPSt, GPSseg, etc) trariam, em minha análise, o aumento da margem/resultado em minha operação.',
    amount: 0,
  },
  {
    formsId: 'entry.993043083',
    id: '6',
    title:
      'Acredito que investir em recursos tecnológicos de hardware (como celulares, computadores, rádio, impressora, etc) é essencial para performance da equipe.',
    amount: 0,
  },
  {
    formsId: 'entry.1336827116',
    id: '7',
    title:
      'Investimentos em equipamentos/ferramental para meus funcionários são necessários para uma operação mais eficiente.',
    amount: 0,
  },
  {
    formsId: 'entry.1693764024',
    id: '8',
    title:
      'A gestão de frota é o departamento que caso otimizado traria ganhos consideráveis para meu contrato.',
    amount: 0,
  },
  {
    formsId: 'entry.778404463',
    id: '9',
    title:
      'Tenho necessidade de realizar melhor controle das faltas dos colaboradores. Assim, terei melhorias em remanejamento de cobertura e/ou gestão de reservas técnicas dentro do contrato.',
    amount: 0,
  },
  {
    formsId: 'entry.1575351104',
    id: '10',
    title:
      'Preciso descobrir incoerências em relação a horas extras dos meus funcionários em tempo hábil para tratamento destas.',
    amount: 0,
  },
  {
    formsId: 'entry.795993153',
    id: '11',
    title:
      'Desejo otimizar a produtividade do meu contrato por meio de gerenciamento de aplicação de horas trabalhadas dos meus funcionários.',
    amount: 0,
  },
  // Pessoas
  {
    formsId: 'entry.987109077',
    id: '12',
    title:
      'Investir em planejamento/controle da qualidade e quantidade de treinamentos, bem como demandas habilitatórias de cada função é essencial para meu contrato.',
    amount: 0,
  },
  {
    formsId: 'entry.1213904842',
    id: '13',
    title:
      'Acredito que melhorias nos processos de recrutamento e seleção iriam reduzir os problemas constantes de posições abertas no meu contrato.',
    amount: 0,
  },
  {
    formsId: 'entry.1520097046',
    id: '14',
    title:
      'Desejo mais proximidade no atendimento com regionais e apoio corporativo. Acredito que isso melhoraria a comunicação/efetividade deste.',
    amount: 0,
  },
  {
    formsId: 'entry.705843169',
    id: '15',
    title:
      'Manter profissionais qualificados, competentes e talentosos é uma necessidade que tenho no meu setor/área. Isso me traria ganhos inclusive de retenção de conhecimento.',
    amount: 0,
  },
  {
    formsId: 'entry.385008684',
    id: '16',
    title:
      'Tenho interesse em aumentar a sinergia de trabalho e comunicação com as outras empresas/soluções do grupo GPS.',
    amount: 0,
  },
  {
    formsId: 'entry.715335081',
    id: '17',
    title:
      'Em minha opinião, deveria existir investimentos para aprimorar a comunicação interna eliminando/reduzindo ruídos.',
    amount: 0,
  },
  {
    formsId: 'entry.1526363164',
    id: '18',
    title:
      'Desejo investir nos processos de premiação e meritocracia que existem no Grupo GPS dentro do meu contrato.',
    amount: 0,
  },
  // Cliente
  {
    formsId: 'entry.1089896688',
    id: '19',
    title:
      'Acredito que é necessário aprimorar o alinhamento das expectativas operacionais com os Clientes.',
    amount: 0,
  },
  {
    formsId: 'entry.1604953973',
    id: '20',
    title:
      'Acredito que é necessário uma desburocratização para atender as ocorrências administrativas dos Clientes.',
    amount: 0,
  },
  {
    formsId: 'entry.808667056',
    id: '21',
    title:
      'Desejo melhorar o processo de medição de satisfação/insatisfação do Cliente.',
    amount: 0,
  },
  {
    formsId: 'entry.1090677848',
    id: '22',
    title:
      'Propostas comerciais mais ágeis e assertivas irão trazer muito ganho para relação entre a GPS e o Cliente.',
    amount: 0,
  },
  {
    formsId: 'entry.619309191',
    id: '23',
    title:
      'Em minha opinião, os processos de implantação/desmobilização relacionados a insumos deveriam ser revistos de forma a prevenir/reduzir desgastes com o Cliente.',
    amount: 0,
  },
  {
    formsId: 'entry.1460228700',
    id: '24',
    title:
      'Em minha opinião, os processos de implantação/desmobilização relacionados a pessoas deveriam ser revistos de forma a prevenir/reduzir desgastes com o Cliente.',
    amount: 0,
  },
] as IFuelDistributionData[];

export default options;
