import styled from 'styled-components';

export const Wrapper = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;

  span.error {
    font-style: normal;
    font-size: 1rem;
    color: var(--red);
    display: inline-block;
    margin-bottom: 0.5rem;
  }
`;
