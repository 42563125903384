import React, { useCallback, useState } from 'react';
import { AiFillWarning } from 'react-icons/ai';

import axios from 'axios';
import { useFuel } from '../../../hooks/fuel';

import Button from '../../../components/Button';
import Header100GallonGame from '../../../components/Header100GallonGame';
import FuelCard from '../FuelCard';

import fuelWhite from '../../../assets/fuel-white.svg';

import { AnimationContainer } from '../styles';
import {
  Container,
  Content,
  RemainingContainer,
  RemainingContent,
  RemainingFuel,
  WarningContainer,
} from './styles';

const FuelDistribution: React.FC = () => {
  const { survey, updateSurvey } = useFuel();
  const [hasLimitError, setHasLimitError] = useState(false);

  const handleGoNextStep = useCallback(async () => {
    try {
      if (survey && survey.fuelDistribution && survey.personalData) {
        const params = new URLSearchParams();

        survey.fuelDistribution.map(item => {
          params.append(item.formsId, String(item.amount));
          return item;
        });

        params.append('entry.1739672761', survey.slug);
        params.append('entry.1909788670', survey.personalData?.role);
        params.append('entry.1453060959', survey.personalData?.regional);
        params.append('entry.1657491673', survey.personalData?.segment);
        params.append('entry.673056816', String(survey.personalData?.month));
        params.append('entry.398409595', String(survey.personalData?.year));

        params.append(
          'entry.267172835',
          survey.personalData?.email ? String(survey.personalData?.email) : '',
        );

        await axios.post(
          'https://docs.google.com/forms/u/1/d/e/1FAIpQLSf2uUlDx-cKyc7nRkSMz06tPxNGGYZGl2Y7DRtoKvTd_3MhAw/formResponse',
          params,
        );
      }
    } catch (error) {
      if (error.toString().startsWith('Error: Network Error')) {
        if (survey) {
          window.scrollTo(0, 0);
          updateSurvey({ ...survey, step: 'finish' });
        }
      } else {
        alert(
          'Não foi possível enviar as suas resposta, tente novamente mais tarde.',
        );
      }
    }
  }, [survey, updateSurvey]);

  const handleSetErrorLimit = useCallback(
    (newErrorLimit: boolean) => {
      if (hasLimitError !== newErrorLimit) setHasLimitError(newErrorLimit);
    },
    [hasLimitError],
  );

  return (
    <>
      <RemainingContainer hasLimitError={hasLimitError}>
        <RemainingContent>
          <h1>Saldo</h1>
          <RemainingFuel>
            <p>{survey?.remainingFuel}</p>
            <img src={fuelWhite} alt="ìcone do saldo restante" />
          </RemainingFuel>
        </RemainingContent>

        {hasLimitError && (
          <span className="error">Saldo insuficiente para essa adição</span>
        )}
      </RemainingContainer>

      <AnimationContainer>
        <Container>
          <Header100GallonGame left />

          <Content>
            <h1>Bem-vindo a nossa base de lançamentos</h1>

            <h2>
              Abasteça de acordo com as suas prioridades. Fique de olho no seu
              saldo, o combustível é um recurso finito. Faça suas escolhas com
              sabedoria!
            </h2>

            {survey?.fuelDistribution.map((item, index) => (
              <FuelCard
                key={item.id}
                questionNumber={index + 1}
                item={item}
                handleSetErrorLimit={handleSetErrorLimit}
              />
            ))}

            {!!(survey && survey?.remainingFuel < 3) ? (
              <Button
                disabled={!!(survey && survey.remainingFuel > 2)}
                type="button"
                onClick={handleGoNextStep}
              >
                Enviar
              </Button>
            ) : (
              <WarningContainer>
                <AiFillWarning />
                <p>
                  Gaste ao menos mais {survey && survey?.remainingFuel - 2}{' '}
                  {survey && survey.remainingFuel > 1 ? 'galões' : 'galão'} para
                  poder Enviar.
                </p>
              </WarningContainer>
            )}
          </Content>
        </Container>
      </AnimationContainer>
    </>
  );
};

export default FuelDistribution;
