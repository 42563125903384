import React, {
  useState,
  createContext,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import api from '../services/api';
import dataOptions from '../pages/PrioritySurvey/data';

export type IPriorityNumbers = '-2' | '-1' | '0' | '1' | '2';

export interface IOption {
  id: string;
  name: string;
  priority: IPriorityNumbers;
}

type IStepOptions =
  | 'email'
  | 'initialBest'
  | 'best'
  | 'initialWrost'
  | 'wrost'
  | 'finish';

interface IInitialGetSurveyData {
  name: string;
  description: string;
  slug: string;
  options: IOption[];
  createdAt: string;
  updatedAt: string;
}

interface IInitialGetSurvey {
  survey: IInitialGetSurveyData;
}

interface ISurvey {
  name: string;
  description: string;
  slug: string;
  email: string | null;
  options: IOption[];
  step: IStepOptions;
}

interface IPriorityContextData {
  survey: ISurvey | null;
  updateSurvey(data: ISurvey): void;
  setSlug(data: string): void;
}

const PriorityContext = createContext<IPriorityContextData>(
  {} as IPriorityContextData,
);

const PriorityProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const [slug, setSlug] = useState<string | null>(null);
  const [survey, setSurvey] = useState<ISurvey | null>(null);

  useEffect(() => {
    async function fetchSurveyData(): Promise<void> {
      try {
        if (slug) {
          const response = await api.get<IInitialGetSurvey>(`/survey/${slug}`);

          setSurvey({
            name: response.data.survey.name,
            description: response.data.survey.description,
            slug: response.data.survey.slug,
            email: null,
            options: dataOptions as IOption[],
            step: 'email',
          });
        }
      } catch (error) {
        alert('Código de pesquisa inválido, tente novamente.');
        history.push('/');
      }
    }

    fetchSurveyData();
  }, [history, slug]);

  const updateSurvey = useCallback(async (data: ISurvey) => {
    setSurvey(data);
  }, []);

  return (
    <PriorityContext.Provider
      value={{
        survey,
        updateSurvey,
        setSlug,
      }}
    >
      {children}
    </PriorityContext.Provider>
  );
};

function usePriority(): IPriorityContextData {
  const context = useContext(PriorityContext);

  if (!context) {
    throw new Error('usePriority must be used within PriorityProvider');
  }

  return context;
}

export { PriorityProvider, usePriority };
