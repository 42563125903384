import styled from 'styled-components';

export const Container = styled.div`
  max-width: 900px;
  margin: 0px auto;
  padding: 5rem 0;
  display: flex;
  flex-direction: column;
  position: relative;
`;
