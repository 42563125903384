import React, { useCallback } from 'react';

import { useFuel } from '../../../hooks/fuel';

import Button from '../../../components/Button';
import Header100GallonGame from '../../../components/Header100GallonGame';

import refilTutorial from '../../../assets/refilTutorial.gif';
import remainingFuelTutorial from '../../../assets/remainingFuelTutorial.gif';

import { AnimationContainer } from '../styles';
import { Container } from './styles';

const Tutorial: React.FC = () => {
  const { survey, updateSurvey } = useFuel();

  const handleGoNextStep = useCallback(() => {
    if (survey) {
      window.scrollTo(0, 0);
      updateSurvey({ ...survey, step: 'fuelDistribution' });
    }
  }, [survey, updateSurvey]);

  return (
    <AnimationContainer>
      <Container>
        <Header100GallonGame />

        <h1>Tutorial</h1>

        <h2>
          Distribua a quantidade de combustível para as suas maiores
          prioridades.
        </h2>

        <img src={refilTutorial} alt="Tutorial de abastecer prioridade" />

        <h2>
          Observe o seu saldo de combustível ao preencher. Você deve gastar ao
          menos 98 galões para enviar as respostas.
        </h2>

        <img src={remainingFuelTutorial} alt="Tutorial de galões restantes" />

        <h2>Clique em Começar para iniciar as decolagens.</h2>

        <Button type="button" onClick={handleGoNextStep}>
          Começar
        </Button>
      </Container>
    </AnimationContainer>
  );
};

export default Tutorial;
