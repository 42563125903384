import React, { useCallback, useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { FiMail } from 'react-icons/fi';

import { usePriority } from '../../../hooks/priority';
import getValidationErrors from '../../../utils/getValidationErrors';

import Button from '../../../components/Button';
import Input from '../../../components/Input';

import logo from '../../../assets/rocket-spark.svg';

import { Container, Top, Content } from './styles';
import { AnimationContainer } from '../styles';

interface IEmailFormData {
  email: string;
}

const Email: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { survey, updateSurvey } = usePriority();

  const handleSubmit = useCallback(
    async (data: IEmailFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('Por favor, insira o seu e-mail')
            .email('Digite um e-mail válido'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (survey) {
          updateSurvey({ ...survey, email: data.email, step: 'initialBest' });
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
      }
    },
    [survey, updateSurvey],
  );

  return (
    <AnimationContainer>
      <Container>
        <Top>
          <img src={logo} alt="Logo GPS Inova" />
          <h1>GPS Inova Survey</h1>
        </Top>

        <Content>
          <h2>
            Ajude-nos a lancar esse foguete, o objetivo deste questionário é
            priorizar funcionalidades para o{' '}
            <strong>{survey?.name && `${survey.name}`}</strong>.
          </h2>

          <h2>{survey?.description}</h2>

          <h2>
            Esse questionário tem duração média de 2 minutos, digite o seu
            e-mail da GPS e clique em Começar.
          </h2>
        </Content>

        <Form ref={formRef} onSubmit={handleSubmit}>
          <Input
            name="email"
            icon={FiMail}
            type="text"
            placeholder="Digite o seu e-mail da GPS"
          />

          <Button type="submit">Começar</Button>
        </Form>

        <h3>
          Esse questionário será de uso interno, não serão compartilhados os
          seus dados com terceiros
        </h3>
      </Container>
    </AnimationContainer>
  );
};

export default Email;
