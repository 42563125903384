import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  :root {
    --white: #fff;
    --background: #f2f3f5;
    --text: #f2f3f5;
    --title: #2e384d;
    --red: #e83f5b;
    --green: #4cd62b;

    --primary: #007cba;
    --primary-dark: #006ba1;
    --primary-darker: #005a87;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    color: var(--text);
    height: 100vh;
  }

  @media (max-width: 1080px) {
    html {
      font-size: 93.75%;
    }
  }

  @media (max-width: 720px) {
    html {
      font-size: 87.5%;
    }
  }

  body,
  input,
  textarea,
  button {
    font: 400 1rem 'Open Sans', Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased !important;
      text-rendering: optimizelegibility;
  }

  button {
    cursor: pointer;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  ul {
    list-style-type: none;
  }
`;
