import React, { useCallback, useState } from 'react';

import Button from '../../../components/Button';

import checkCircle from '../../../assets/checkCircle.svg';

import { AnimationContainer } from '../styles';
import { ListContainer, ListItem, Top } from './styles';

import {
  IOption,
  IPriorityNumbers,
  usePriority,
} from '../../../hooks/priority';

type INextPossibleSteps = 'best' | 'initialWrost' | 'wrost' | 'finish';

interface IListProps {
  stepNumber: number;
  options: IOption[];
  priority: IPriorityNumbers;
  nextPriority: IPriorityNumbers;
  nextStep: INextPossibleSteps;
  maxItens: 6 | 3;
}

const List: React.FC<IListProps> = ({
  stepNumber,
  options,
  priority,
  nextPriority,
  nextStep,
  maxItens,
}) => {
  const { survey, updateSurvey } = usePriority();

  const [currentOptions, setCurrentOptions] = useState(
    options.map(item => ({ ...item, selected: false })),
  );
  const [numberOfSelectedItems, setNumberOfSelectedItems] = useState(0);

  const handleGoNextStep = useCallback(() => {
    if (survey?.options) {
      const newSurveyOptions = survey.options.map(surveyItem => {
        const foundItem = currentOptions.find(
          option => option.id === surveyItem.id,
        );

        if (foundItem) {
          return foundItem;
        }

        return surveyItem;
      }, []);

      updateSurvey({ ...survey, step: nextStep, options: newSurveyOptions });
    }
  }, [updateSurvey, survey, nextStep, currentOptions]);

  const handleUpdateItem = useCallback(
    (optionId: string) => {
      const newCurrentOptions = currentOptions.map(item => {
        if (item.id === optionId) {
          if (numberOfSelectedItems < maxItens) {
            if (!item.selected) {
              setNumberOfSelectedItems(numberOfSelectedItems + 1);
              return { ...item, selected: true, priority: nextPriority };
            }
            setNumberOfSelectedItems(numberOfSelectedItems - 1);
            return { ...item, selected: false, priority };
          }
          if (item.selected) {
            setNumberOfSelectedItems(numberOfSelectedItems - 1);
            return { ...item, selected: false, priority };
          }
        }
        return item;
      });

      setCurrentOptions(newCurrentOptions);
    },
    [currentOptions, maxItens, nextPriority, numberOfSelectedItems, priority],
  );

  return (
    <AnimationContainer>
      <Top>
        <span>{stepNumber}</span>
        <p>
          Selecione as <strong>{maxItens}</strong> funcionalidades principais
          que você{' '}
          <strong>
            {(stepNumber === 1 || stepNumber === 2) && 'gostaria'}{' '}
            {(stepNumber === 3 || stepNumber === 4) && 'menos gostaria'}{' '}
          </strong>{' '}
          de utilizar no <strong>{survey?.name}</strong>.
        </p>
      </Top>

      <ListContainer>
        {currentOptions.map((option, index) => (
          <ListItem
            key={option.id}
            onClick={() => handleUpdateItem(option.id)}
            selected={option.selected}
            lowOpacity={numberOfSelectedItems === maxItens && !option.selected}
          >
            <p>{index + 1}</p>
            <strong>{option.name}</strong>
            {option.selected && <img src={checkCircle} alt="Item Marcado" />}
          </ListItem>
        ))}
      </ListContainer>

      {numberOfSelectedItems === maxItens && (
        <Button type="button" onClick={handleGoNextStep}>
          Avançar
        </Button>
      )}
    </AnimationContainer>
  );
};

export default List;
