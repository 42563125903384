import React, { useCallback } from 'react';

import fuelDark from '../../../assets/fuel-dark.svg';

import {
  Container,
  Top,
  Bottom,
  BottomLeft,
  BottomFuel,
  EmptyButton,
  BottomRight,
  PlusButton,
} from './styles';

import { IFuelDistributionData, useFuel } from '../../../hooks/fuel';

interface IFuelCard {
  questionNumber: number;
  item: IFuelDistributionData;
  handleSetErrorLimit(newErrorLimit: boolean): void;
}

const FuelCard: React.FC<IFuelCard> = ({
  questionNumber,
  item,
  handleSetErrorLimit,
}) => {
  const { survey, updateSurvey } = useFuel();

  const handleResetFuelCardAmount = useCallback(
    (decreasedAmount: number) => {
      if (survey) {
        const newFuelDistribution = survey.fuelDistribution.map(fuelCard => {
          if (item.id === fuelCard.id) {
            if (fuelCard.amount > 0) handleSetErrorLimit(false);

            return {
              ...fuelCard,
              amount: 0,
            };
          }

          return fuelCard;
        });

        updateSurvey({
          ...survey,
          fuelDistribution: newFuelDistribution,
          remainingFuel: survey.remainingFuel + decreasedAmount,
        });
      }
    },
    [handleSetErrorLimit, item.id, survey, updateSurvey],
  );

  const handleIncreseAmount = useCallback(
    (incresedAmount: number) => {
      if (survey && survey.remainingFuel - incresedAmount >= 0) {
        handleSetErrorLimit(false);
        const newFuelDistribution = survey.fuelDistribution.map(fuelCard => {
          if (item.id === fuelCard.id) {
            return {
              ...fuelCard,
              amount: fuelCard.amount + incresedAmount,
            };
          }
          return fuelCard;
        });

        updateSurvey({
          ...survey,
          fuelDistribution: newFuelDistribution,
          remainingFuel: survey.remainingFuel - incresedAmount,
        });
      } else {
        handleSetErrorLimit(true);
      }
    },
    [handleSetErrorLimit, item.id, survey, updateSurvey],
  );

  return (
    <Container>
      <Top>
        <h1>{questionNumber}</h1>

        <h2>{item.title}</h2>
      </Top>

      <Bottom>
        <BottomLeft>
          <BottomFuel>
            <img src={fuelDark} alt="Quantidade de combustível" />
            <p>{item.amount}</p>
          </BottomFuel>
          <EmptyButton
            type="button"
            onClick={() => handleResetFuelCardAmount(item.amount)}
          >
            Esvaziar
          </EmptyButton>
        </BottomLeft>

        <BottomRight>
          <PlusButton type="button" onClick={() => handleIncreseAmount(3)}>
            +3
          </PlusButton>
          <PlusButton type="button" onClick={() => handleIncreseAmount(5)}>
            +5
          </PlusButton>
          <PlusButton type="button" onClick={() => handleIncreseAmount(8)}>
            +8
          </PlusButton>
        </BottomRight>
      </Bottom>
    </Container>
  );
};

export default FuelCard;
