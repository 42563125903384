import styled from 'styled-components';

import stars from '../../../assets/stars.svg';

export const Wrapper = styled.div`
  background: linear-gradient(90deg, #10202f, #231f20);
`;

export const BackgroundImage = styled.div`
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0rem 16px 16px 16px;
  background: url(${stars});
  overflow-x: hidden;
`;

export const Container = styled.div``;

export const Bottom = styled.div`
  margin-top: auto;
  margin: auto auto 0 auto;
  display: flex;
  align-items: center;

  img {
    height: 22px;
    width: 22px;
    margin-right: 4px;
  }

  span {
    font-size: 0.8rem;
    font-weight: lighter;
    text-align: center;
    max-width: 400px;
    opacity: 0.8;
  }
`;
